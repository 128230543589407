import logo from '../src/img/logos/logo-plusdatum.png';
import bpm from "../src/img/portfolio/bpm-andina-international.png";
import soporte from "../src/img/portfolio/soporte-tecnico-centro-aseo.png";
import software from "../src/img/portfolio/software-a-la-medida-soluciones-avanzadas-tic.png";
import aquiniciamos from "../src/img/about/aqui-iniciamos.jpg";
import mayorproyecto from "../src/img/about/mayor-proyecto.jpg";
import actualizacion from "../src/img/about/proceso-actualizacion.jpg"
import jjqs from "../src/img/team/john-jairo-quitora.jpg"
import njmv from "../src/img/team/nelly-mahecha.jpg"
import bjmv from "../src/img/team/ben-mahecha.jpg"

function App() {
  return (
    <body id="page-top" className="index">
      <nav className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header page-scroll">
            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
              <span className="sr-only">Navegación</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a title="Servicios" href="#services" className="page-scroll">Servicios</a>
              </li>
              <li>
                <a title="Portafolio" href="#portfolio" className="page-scroll">Portafolio</a>
              </li>
              <li>
                <a title="Acerca de" href="#about" className="page-scroll">Acerca de</a>
              </li>
              <li>
                <a title="Equipo" href="#team" className="page-scroll">Equipo</a>
              </li>
              <li>
                <a title="Contacto" href="#contact" className="page-scroll">Contacto</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <header>
        <div className="container">
          <div className="intro-text">
            <div className="intro-lead-in"><img title="Logo Plusdatum" src={logo} alt="logo plusdatum" className="img-responsive" style={{display: "inline"}}/></div>
            <h1 className="intro-heading">Materializamos sus ideas en forma digital</h1>
            <a title="Leer más" href="#services" className="page-scroll btn btn-xl">Leer más</a>
          </div>
        </div>
      </header>

      <section id="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading">Servicios</h2>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-4">
              <span className="fa-stack fa-4x">
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-desktop fa-stack-1x fa-inverse"></i>
              </span>
              <h4 className="service-heading">Software a la medida</h4>
              <p className="text-muted">Soluciones para un mercado en constante evolución; un sitio web es una vía de publicidad y de conexión con sus clientes.</p>
            </div>
            <div className="col-md-4">
              <span className="fa-stack fa-4x">
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-dashboard fa-stack-1x fa-inverse"></i>
              </span>
              <h4 className="service-heading">Business Process Management</h4>
              <p className="text-muted">Integramos la articulación de la estrategia, los procesos y la tecnología por medio de una herramienta moderna y flexible.</p>
            </div>
            <div className="col-md-4">
              <span className="fa-stack fa-4x">
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-keyboard-o fa-stack-1x fa-inverse"></i>
              </span>
              <h4 className="service-heading">Soporte t&eacute;cnico</h4>
              <p className="text-muted">Contamos con una amplia experiencia en asistencia técnica, suministrando cualquier solución en hardware y software.</p>
            </div>
            <div className="col-md-4">
              <span className="fa-stack fa-4x">
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-lock fa-stack-1x fa-inverse"></i>
              </span>
              <h4 className="service-heading">Seguridad Informática</h4>
              <p className="text-muted">Ofrecemos estudios personalizados de la seguridad actual de la web y protección efectiva de los sistemas de información.</p>
            </div>
            <div className="col-md-4">
              <span className="fa-stack fa-4x">
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-search fa-stack-1x fa-inverse"></i>
              </span>
              <h4 className="service-heading">SEO</h4>
              <p className="text-muted">Facilitamos a los buscadores que reconozcan su sitio web en un área especifica y así poder adquirir un mayor posicionamiento.</p>
            </div>
          </div>
        </div>
      </section>

      <section id="portfolio" className="bg-light-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading">Portafolio</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6 portfolio-item">
              <a title="BPM Andina International S.A.S" href="#portfolioModal1" className="portfolio-link" data-toggle="modal">
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content">
                    <i className="fa fa-plus fa-3x"></i>
                  </div>
                </div>
                <img title="BPM Andina International S.A.S" src={bpm} alt="bpm andina international s.a.s" className="img-responsive" />
              </a>
              <div className="portfolio-caption">
                <h4>Andina International</h4>
                <p className="text-muted">Diseño de BPM</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 portfolio-item">
              <a title="Soporte técnico Centro Aseo" href="#portfolioModal2" className="portfolio-link" data-toggle="modal">
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content">
                    <i className="fa fa-plus fa-3x"></i>
                  </div>
                </div>
                <img title="Soporte técnico Centro Aseo" src={soporte} alt="soporte técnico centro aseo" className="img-responsive" />
              </a>
              <div className="portfolio-caption">
                <h4>Centro Aseo</h4>
                <p className="text-muted">Soporte técnico</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 portfolio-item">
              <a title="Software a la medida Soluciones Avanzadas TIC" href="#portfolioModal3" className="portfolio-link" data-toggle="modal">
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content">
                    <i className="fa fa-plus fa-3x"></i>
                  </div>
                </div>
                <img title="Software a la medida Soluciones Avanzadas TIC" src={software} alt="software a la medida soluciones avanzadas tic" className="img-responsive" />
              </a>
              <div className="portfolio-caption">
                <h4>Soluciones Avanzadas TIC</h4>
                <p className="text-muted">Software a la medida</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading">Acerca de</h2>
              <h3 className="section-subheading text-muted">Nuestra historia.</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <ul className="timeline">
                <li>
                  <div className="timeline-image">
                    <img title="Aquí iniciamos" src={aquiniciamos} alt="aqui iniciamos" className="img-circle img-responsive"/>
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>2011</h4>
                      <h4 className="subheading">Aquí iniciamos</h4>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">Primeros pasos con servicios de mantenimiento preventivo y correctivo.</p>
                    </div>
                  </div>
                </li>
                <li className="timeline-inverted">
                  <div className="timeline-image">
                    <img title="Mayor proyecto" src={mayorproyecto} alt="mayor proyecto" className="img-circle img-responsive"/>
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>Septiembre 2013</h4>
                      <h4 className="subheading">El mayor proyecto</h4>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">Se firma con Andina International S.A.S proyecto de creación de software para la gestión de procesos de negocio (BPM).</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="timeline-image">
                    <img title="Proceso de actualización" src={actualizacion} alt="proceso de actualización" className="img-circle img-responsive" />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>Noviembre 2014</h4>
                      <h4 className="subheading">Proceso de actualización</h4>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">.Acuerdo con Soluciones Avanzadas TIC para actualización de software en Visual Basic 6.0.</p>
                    </div>
                  </div>
                </li>
                <li className="timeline-inverted">
                  <div className="timeline-image">
                    <h4>Se parte De Nuestra Historia!</h4>
                      </div>
                    </li>
                  </ul>
                </div>
            </div>
          </div>
      </section>

      <section id="team" className="bg-light-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading">Nuestro Equipo</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="team-member">
                <img title="John Jairo Quitora - Ingeniero de sistemas" src={jjqs} alt="john jairo quitora ingeniero de sistemas" className="img-responsive img-circle" />
                  <h4>John Jairo Quitora</h4>
                  <p className="text-muted">Ingeniero de sistemas</p>
                  <ul className="list-inline social-buttons">
                    <li><a title="Twitter John Jairo Quitora" href="https://twitter.com/johnjota82" target="_blank"><i className="fa fa-twitter"></i></a>
                    </li>
                    <li><a title="Facebook John Jairo Quitora" href="https://facebook.com/john.j.quitora" target="_blank"><i className="fa fa-facebook"></i></a>
                    </li>
                  </ul>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="team-member">
                <img title="Nelly Mahecha - Community manager" src={njmv} alt="nelly mahecha community manager" className="img-responsive img-circle" />
                  <h4>Nelly Mahecha</h4>
                  <p className="text-muted">Ingeniera de sistemas</p>
                  <ul className="list-inline social-buttons">
                    <li><a title="Twitter Nelly Mahecha" href="https://twitter.com/nejumava" target="_blank"><i className="fa fa-twitter"></i></a>
                    </li>
                  </ul>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="team-member">
                <img title="Ben Mahecha - Ingeniero de automatización electrónica" src={bjmv} alt="ben mahecha ingeniero de automatización electrónica" className="img-responsive img-circle" />
                  <h4>Ben Mahecha</h4>
                  <p className="text-muted">Ingeniero de automatización electrónica</p>
                  <ul className="list-inline social-buttons">
                    <li><a title="Facebook Ben Mahecha" href="https://www.facebook.com/ben.mahecha?fref=ts" target="_blank"><i className="fa fa-facebook"></i></a>
                    </li>
                  </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center" itemscope itemtype="http://schema.org/Organization">
              <h2 className="section-heading" itemprop="name">Plusdatum</h2>
              <a className="text-muted" href="mailto:contacto@plusdatum.com" itemprop="email">contacto@plusdatum.com</a>
              <p className="text-muted" itemprop="telephone">+57 3213122156</p>
              <p className="text-muted" itemprop="address">Bogot&aacute;</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <form name="sentMessage" id="contactForm" novalidate>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Su Nombre *" id="name" required data-validation-required-message="Por favor ingrese su nombre." />
                        <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group">
                      <input type="email" className="form-control" placeholder="Su Email *" id="email" required data-validation-required-message="Por favor ingrese su email." />
                        <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group">
                      <input type="tel" className="form-control" placeholder="Su Teléfono *" id="phone" required data-validation-required-message="Por favor ingrese su número telefónico." />
                        <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <textarea className="form-control" placeholder="Su Mensaje *" id="message" required data-validation-required-message="Por favor ingrese un mensaje."></textarea>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="col-lg-12 text-center">
                    <div id="success"></div>
                    <button type="submit" className="btn btn-xl">Enviar Mensaje</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-4" itemscope itemtype="http://schema.org/Organization">
              <span className="copyright">Copyright &copy; 2023 - <a href="http://www.plusdatum.com/" itemprop="url">Plusdatum.com</a></span>
            </div>
            <div className="col-md-4">
              <ul className="list-inline social-buttons">
                <li><a title="Twitter Plusdatum" href="https://twitter.com/plusdatum" target="_blank"><i className="fa fa-twitter"></i></a>
                </li>
                <li><a title="Facebook Plusdatum" href="https://www.facebook.com/plusdatum" target="_blank"><i className="fa fa-facebook"></i></a>
                </li>
                <li><a title="Google+ Plusdatum" href="https://plus.google.com/+Plusdatum" target="_blank" rel="publisher"><i className="fa fa-google"></i></a>
                </li>
                <li><a title="Validación W3C Html Plusdatum" href="http://validator.w3.org/check?uri=http://www.plusdatum.com/" target="_blank"><i className="fa fa-html5"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="portfolio-modal modal fade" id="portfolioModal1" tabindex="-1" role="dialog" aria-hidden="true">
        <div className="modal-content">
          <div className="close-modal" data-dismiss="modal">
            <div className="lr">
              <div className="rl">
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-lg-offset-2">
                <div className="modal-body">
                  <h2>Diseño de BPM</h2>
                  <p className="item-intro text-muted">Automatización de procesos</p>
                  <img title="BPM Andina International S.A.S" src={bpm} alt="bpm andina international" className="img-responsive img-centered" />
                    <p>Integración y articulación de la estrategia, los procesos y la tecnología por medio de una herramienta moderna, flexible y cuyo manejo está al alcance de cualquier persona sin necesidad de conocimientos técnicos, dándole la oportunidad de generar valor a su negocio.</p>
                    <p>
                      <strong>Desea un acceso para demostración?</strong> Envíenos un mensaje desde el formulario de contacto.</p>
                    <ul className="list-inline">
                      <li>Fecha: Septiembre 2013</li>
                      <li>Cliente: Andina International S.A.S</li>
                      <li>Categoria: Business Process Management</li>
                    </ul>
                    <button type="button" className="btn btn-primary" data-dismiss="modal"><i className="fa fa-times"></i> Cerrar Proyecto</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="portfolio-modal modal fade" id="portfolioModal2" tabindex="-1" role="dialog" aria-hidden="true">
        <div className="modal-content">
          <div className="close-modal" data-dismiss="modal">
            <div className="lr">
              <div className="rl">
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-lg-offset-2">
                <div className="modal-body">
                  <h2>Soporte técnico</h2>
                  <p className="item-intro text-muted">Mantenimientos preventivos y correctivos.</p>
                  <img title="Soporte técnico Centro Aseo" src={soporte} alt="soporte técnico centro aseo" className="img-responsive img-centered" />
                    <p></p>
                    <p>Solicite su soporte técnico a través del formulario de contacto.</p>
                    <button type="button" className="btn btn-primary" data-dismiss="modal"><i className="fa fa-times"></i> Cerrar Proyecto</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="portfolio-modal modal fade" id="portfolioModal3" tabindex="-1" role="dialog" aria-hidden="true">
        <div className="modal-content">
          <div className="close-modal" data-dismiss="modal">
            <div className="lr">
              <div className="rl">
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-lg-offset-2">
                <div className="modal-body">
                  <h2>Software a la medida</h2>
                  <p className="item-intro text-muted">Actualización de plataforma.</p>
                  <img title="Software a la medida Soluciones Avanzadas TIC" src={software} alt="software a la medida soluciones avanzadas tic" className="img-responsive img-centered" />
                    <p>Actualización en la conexión desde Visual Basic 6.0 (VB6) a bases de datos, pasando de Access a un servidor con el motor SQL Server 2008 R2 a 64 bits.</p>
                    <p><strong>Solicite información</strong> a través del formulario de contacto.</p>
                    <button type="button" className="btn btn-primary" data-dismiss="modal"><i className="fa fa-times"></i> Cerrar Proyecto</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}

export default App;
